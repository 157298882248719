@import "../../assets/scss/base";

main {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;

    @include breakpoint(tablet) {
        padding: 24px;
        align-items: center;
        justify-content: center;

        @include flexbox(row, center, center, 24px);
    }

    .content {
        position: relative;
        border-radius: 10px;
        background-color: $PRIMARY_80;
        width: 100%;
        overflow: auto;
        padding-bottom: 1.5rem;
        height: 100%;

        @include breakpoint(laptop) {
            height: 100%;
        }

        &__body {
            padding: 1.5rem 1.5rem 0;

            @include breakpoint(mobile) {
                padding: 1rem;
            }
            h5 {
                font-weight: bolder;
                font-size: $MEDIUM;
            }
        }
    }
}

.Dashboard {
    height: calc(100% - 2.5rem);

    .row {
        height: 100%;
    }

    .notifications__header {
        margin-top: 0;
    }
}

.content--card {
    background-color: white;
    border-radius: 1rem;
    width: 100%;
    padding: 13px;
    box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);
    padding: 1.5rem 1.25rem;
    height: calc(100vh - 6rem);
}

.content--card-2 {
    background-color: white;
    border-radius: 1rem;
    width: 100%;
    padding: 13px;
    box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);
    padding: 1.5rem 1.25rem;
}