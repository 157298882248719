@import "../../assets/scss/base";

.StatusAccompagnement {
    display: flex;
    align-items: center;
    align-self: stretch;
    margin-bottom: 1.875rem;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;

    &__step {
        display: flex;
        padding: 0.625rem;
        align-items: center;
        border-radius: 0.5rem;
        border: 2px solid $PRIMARY_40;
        background: var(--Neutral-100, #fff);
        gap: 0.625rem;
        color: $PRIMARY_40;
        height: 3.75rem;
        flex: auto;
        justify-content: space-between;

        span {
            font-family: Raleway;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.625rem;
        }

        &__success {
            color: $SUCCESS_30;
            border-color: $SUCCESS_30;
        }

        &__disabled {
            background-color: $PRIMARY_70;
            color: $PRIMARY_60;
            border: 0;
        }
    }
}
