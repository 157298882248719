.VideoFrame {
  position: relative; // Correspond à relative
  width: 100%; // Correspond à w-full
  max-width: 32rem; // Correspond à max-w-lg (lg = 32rem = 512px)
  margin-left: auto; // Correspond à mx-auto (centrage horizontal)
  margin-right: auto;

  .shadow-player {
    box-shadow: rgba(23, 28, 96, 0.78) 0px 5px, rgba(27, 36, 159, 0.31) 0px 10px, rgba(23, 52, 96, 0.25) 0px 15px, rgba(23, 52, 96, 0.06) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;
  }

  .hh {
    position: absolute;
  }

  .custom-card {
    max-width: 498px;
    max-height: 280px;

    min-width: 300px;
    min-height: 230px;

    border-radius: 12px; // Correspond à rounded-xl
    overflow: hidden; // Empêche le contenu de dépasser les bords arrondis
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1); // Correspond à shadow-lg
  }

}
.custom-player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.custom-text {
  color: white; // Correspond à text-white
  width: 4rem; // Correspond à w-16 (16 * 0.25rem = 4rem)
  height: 4rem; // Correspond à h-16 (16 * 0.25rem = 4rem)
}