.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.underline {
    text-decoration: underline;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}

.d-none {
    display: none;
}

.opacity-0 {
    opacity: 0;
}

.d-flex {
    display: flex !important;
}

.flex-none {
    flex: none;
}

.flex-wrap {
    flex-wrap: wrap;
}

.d-block {
    display: block;
}

.center {
    display: grid;
    place-items: center;
}

.align-items {
    &-center {
        align-items: center;
    }

    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }
}

.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}

.flex-reverse {
    flex-direction: row-reverse;
}

.gap-05 {
    gap: 0.5rem;
}

.m-auto {
    margin: auto;
}

.mt-auto {
    margin-top: auto;
}

@for $i from 1 through 5 {
    .gap-#{$i} {
        gap: #{$i}rem;
    }
}

@for $i from 0 through 5 {
    .mb-#{$i} {
        margin-bottom: #{$i}rem;
    }

    .mt-#{$i} {
        margin-top: #{$i}rem;
    }
}

@for $i from 0 through 5 {
    .p-#{$i} {
        padding: #{$i}rem;
    }
}

.w-100 {
    width: 100%;
}

.min-w-40 {
    min-width: 320px;
}

// loop for width
@for $i from 0 through 100 {
    .w-#{$i} {
        width: unquote("#{$i}%");
    }
}

.h-100 {
    height: 100%;
}

.h-stripe {
    min-height: 370px;
}

.h-50 {
    height: 50%;
}

.h-55 {
    height: 55%;
}

.h-60 {
    height: 60%;
}

.h-70 {
    height: 70%;
}

.h-30 {
    height: 30%;
}

.h-10 {
    height: 10%;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.bold {
    font-weight: bold;
}

.rounded-md {
    border-radius: 12px;
}

.rounded {
    border-radius: 8px;
}

.shadow-md {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

ul li {
    list-style-type: circle;

    padding: 5px;
    margin-left: 35px;
}

$colors: (
    "primary": $PRIMARY_40,
    "secondary": $SECONDARY_40,
    "success": $SUCCESS_40,
    "danger": $ERROR_40,
    "warning": $ACCENT_40,
    "light": $NEUTRAL_100,
);

// loop for colors
@each $name, $color in $colors {
    .bg-#{$name} {
        background-color: $color;
    }

    .text-#{$name} {
        color: $color;
    }
}
